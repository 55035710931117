import { Component, Input, OnInit } from '@angular/core';
import { IAccordionItem } from './interfaces/accordion-item.interface';
import { HEIGHT_TO_AUTO } from 'src/app/shared/animations/height-to-auto.animations';

@Component({
  selector: 'aig-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  animations : [
    HEIGHT_TO_AUTO
  ]
})
export class AccordionComponent implements OnInit {
  
  @Input() prefix: string = '';
  @Input() items: IAccordionItem[] = [];

  public activeItem: number = null;


  constructor() { }

  ngOnInit(): void {
    
  }

  public changeActiveItem(index: number): void {
    if (this.activeItem === index) {
      this.activeItem = null;
    } else {
      this.activeItem = index;
     // const id =  this.prefix + String(index - 1);
      //var el = document.getElementById(id);
    }
  }
}

<div class="aig-accordion u-size-per-100">

  <div class="c-accordion u-pb-def u-overx-hidden" *ngFor="let item of items; index as i">

    <div class="c-accordion__header u-cursor-pointer" (click)="changeActiveItem(item.id)" [id]="prefix + item.id">
      <strong class="c-accordion__title u-family-aig"> {{ item.title }} </strong>
      <i class="c-accordion__arrow u-color-primary1 u-fs-28 icon-chevron-down u-cursor-pointer u-me-3" [ngClass]="activeItem != (i) ? 'c-accordion__arrow' : 'c-accordion__arrow--no-rotated'"></i>
    </div>

    <div *ngIf="activeItem == (item.id)" class="c-accordion__body u-overf-hidden" @accordion>
      <p *ngIf="!item.html">
        {{ item.description }}
      </p>
      <div *ngIf="item.html" [innerHTML]="item.description"></div>
    </div>


  </div>

</div>

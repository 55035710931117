<aig-modal *ngIf="modal == true" position="center" customMaxWidth="60rem">
  <div class="u-p-5 u-border-bottom row u-mx-0" header>
    <div class="col-12">
      <div class="u-fs-40 u-color-primary1 u-fw-400">
        <div *ngIf="cookiesCompletas == false" class="mb-4">
          Utilizamos cookies y tecnologías similares propias y de terceros, de
          sesión o persistentes, para hacer funcionar de manera segura nuestra
          página web y personalizar su contenido Igualmente, utilizamos cookies
          para medir y obtener datos de la navegación que realizas y para
          ajustar la publicidad a tus gustos y preferencias. Puedes configurar y
          aceptar el uso de cookies a continuación. Asimismo, puedes modificar
          tus opciones de consentimiento en cualquier momento visitando nuestra
          Política de Cookies y obtener más información haciendo clic
          <a role="button" (click)="mostrarCookiesCompletas()" style="cursor: pointer;">aquí</a>
        </div>
        <div *ngIf="cookiesCompletas == true" class="u-text-left">
          <div class="post-content">
            <div class="mt-2">
              <div class="">
                <div class="bg-gris">
                  <p class="txt-separador" style="text-align: left">
                    Qué son las cookies
                  </p>
                </div>
              </div>
              <div class="">
                <p>
                  Una&nbsp;<em><strong>cookie</strong></em
                  >&nbsp;es un pequeño fichero de texto que se almacena en su
                  navegador cuando visita casi cualquier página web. Su utilidad
                  es que la web sea capaz de recordar su visita cuando vuelva a
                  navegar por esa página. Las&nbsp;<em>cookies</em>&nbsp;suelen
                  almacenar información de carácter técnico, preferencias
                  personales, personalización de contenidos, estadísticas de
                  uso, enlaces a redes sociales, acceso a cuentas de usuario,
                  etc. El objetivo de la&nbsp;<em>cookie</em>&nbsp;es adaptar el
                  contenido de la web a su perfil y necesidades, sin&nbsp;<em
                    >cookies</em
                  >
                  los servicios ofrecidos por cualquier página se verían
                  mermados notablemente.
                </p>
              </div>
            </div>
            <div class="mt-2">
              <div class="bg-gris">
                <p class="txt-separador" style="text-align: left">
                  Tipos de Cookies
                </p>
              </div>
              <div class="">
                <p>
                  <em><strong>Cookies Técnicas:&nbsp;</strong></em
                  >Son aquéllas que permiten al usuario navegar a través de la
                  página web o aplicación y la utilización de diferentes
                  opciones y/o servicios que en ella existen.
                </p>
                <p>
                  <em><strong>Cookies de personalización:&nbsp;</strong></em
                  >Son aquéllas que permiten al usuario acceder al servicio con
                  algunas características de carácter general que están
                  predefinidas en su terminal o que el propio usuario defina.
                </p>
                <p>
                  <em><strong>Cookies publicitarias:&nbsp;</strong></em
                  >Son aquéllas que permiten la gestión eficaz de espacios
                  publicitarios que se han incluido en la página web o
                  aplicación desde la que se presta el servicio. Permiten
                  adecuar el contenido de la publicidad para que esta sea
                  relevante para el usuario y para evitar mostrar anuncios que
                  el usuario ya haya visto.
                </p>
                <p>
                  <em><strong>Cookies de análisis estadístico:</strong></em>
                  Son aquéllas que permiten realizar el seguimiento y análisis
                  del comportamiento de los usuarios en los sitios web. La
                  información recogida mediante este tipo de ‘cookies’ se
                  utiliza en la medición de actividad de los sitios web y para
                  elaboración de perfiles de navegación de los usuarios en
                  dichos sitios, con el fin de introducir mejoras en el servicio
                  en función de los datos de uso que hacen los usuarios.
                </p>
              </div>
            </div>
            <div class="mt-2">
              <div class="">
                <div class="bg-gris">
                  <p class="txt-separador" style="text-align: left">
                    Identificación de cookies utilizadas en este sitio
                  </p>
                </div>
              </div>
              <div class="">
                <p><strong>Cookies Necesarias:</strong></p>
                <p>
                  Son aquellas cookies que permiten el adecuado funcionamiento
                  de la página web, así como el acceso a áreas seguras, por lo
                  que sin estas cookies la página web no podría funcionar
                  adecuadamente.
                </p>
                <table>
                  <thead>
                    <tr>
                      <th width="200px">TITULAR DEL DOMINIO</th>
                      <th width="500px">NOMBRE</th>
                      <th width="300px">FINALIDAD</th>
                      <th width="300px">CADUCIDAD</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowspan="12">segurodeenfermedadesgraves.es</td>
                      <td>PHPSESSID</td>
                      <td>
                        Conserva los estados de los usuarios en todas las
                        peticiones de la página.
                      </td>
                      <td>Sesión</td>
                    </tr>
                    <tr>
                      <td>cmplz_banner-status</td>
                      <td>
                        Cookie necesaria para la utilización de las opciones y
                        servicios del sitio web.
                      </td>
                      <td>1 año</td>
                    </tr>
                    <tr>
                      <td>cmplz_choice</td>
                      <td>
                        Cookie necesaria para la utilización de las opciones y
                        servicios del sitio web.
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>cmplz_consent_status</td>
                      <td>
                        Cookie necesaria para la utilización de las opciones y
                        servicios del sitio web.
                      </td>
                      <td>1 año</td>
                    </tr>
                    <tr>
                      <td>cmplz_consented_services</td>
                      <td>
                        Cookie necesaria para la utilización de las opciones y
                        servicios del sitio web.
                      </td>
                      <td>1 año</td>
                    </tr>
                    <tr>
                      <td>cmplz_functional</td>
                      <td>
                        Cookie necesaria para la utilización de las opciones y
                        servicios del sitio web.
                      </td>
                      <td>1 año</td>
                    </tr>
                    <tr>
                      <td>cmplz_marketing</td>
                      <td>
                        Cookie necesaria para la utilización de las opciones y
                        servicios del sitio web.
                      </td>
                      <td>1 año</td>
                    </tr>
                    <tr>
                      <td>cmplz_policy_id</td>
                      <td>
                        Cookie necesaria para la utilización de las opciones y
                        servicios del sitio web.
                      </td>
                      <td>1 año</td>
                    </tr>
                    <tr>
                      <td>cmplz_preferences</td>
                      <td>
                        Cookie necesaria para la utilización de las opciones y
                        servicios del sitio web.
                      </td>
                      <td>1 año</td>
                    </tr>
                    <tr>
                      <td>cmplz_statistics</td>
                      <td>
                        Cookie necesaria para la utilización de las opciones y
                        servicios del sitio web.
                      </td>
                      <td>1 año</td>
                    </tr>
                    <tr>
                      <td>cmplz_statistics-anonymous</td>
                      <td>
                        Cookie necesaria para la utilización de las opciones y
                        servicios del sitio web.
                      </td>
                      <td>1 año</td>
                    </tr>
                    <tr>
                      <td>cookiesession1</td>
                      <td>
                        Cookie necesaria para la utilización de las opciones y
                        servicios del sitio web.
                      </td>
                      <td>1 año</td>
                    </tr>
                    <tr>
                      <td>google.com</td>
                      <td>RC:: A</td>
                      <td>
                        Esta cookie se utiliza para distinguir entre humanos y
                        bots. Esto es beneficioso para la web con el objeto de
                        elaborar informes válidos sobre el uso de su web.
                      </td>
                      <td>Persistente</td>
                    </tr>
                    <tr>
                      <td>google.com</td>
                      <td>RC:: C</td>
                      <td>
                        Esta cookie se utiliza para distinguir entre humanos y
                        bots.
                      </td>
                      <td>Sesión</td>
                    </tr>
                  </tbody>
                </table>

                <p>&nbsp;</p>
                <p><strong>Cookies Estadísticas (Analíticas):</strong></p>
                <p>
                  Las cookies estadísticas ayudan a los propietarios de páginas
                  web a comprender cómo interactúan los visitantes con las
                  páginas web reuniendo y proporcionando información de forma
                  anónima.
                </p>
                <table>
                  <thead>
                    <tr>
                      <th width="200px">TITULAR DEL DOMINIO</th>
                      <th width="500px">NOMBRE</th>
                      <th width="300px">FINALIDAD</th>
                      <th width="300px">CADUCIDAD</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowspan="3">Google Analytics</td>
                      <td>_ga</td>
                      <td>
                        Registra una identificación única que se utiliza para
                        generar datos estadísticos acerca de cómo utiliza el
                        visitante el sitio web.
                      </td>
                      <td>2 años</td>
                    </tr>
                    <tr>
                      <td>_gat</td>
                      <td>Utilizada para controlar la tasa de peticiones.</td>
                      <td>1 día</td>
                    </tr>
                    <tr>
                      <td>_id</td>
                      <td>
                        Registra una identificación única que se utiliza para
                        generar datos estadísticos acerca de cómo utiliza el
                        visitante el sitio web.
                      </td>
                      <td>1 día</td>
                    </tr>
                    <tr>
                      <td>segurodeenfermedadesgraves.es</td>
                      <td>cookiesession1</td>
                      <td>
                        Establece un identificador al visitan te. Esto sirve
                        para contabilizar el número de visitantes específicos en
                        la web.
                      </td>
                      <td>1 año</td>
                    </tr>
                  </tbody>
                </table>
                <p>&nbsp;</p>
                <p><strong>Categoría Marketing (Publicidad):</strong></p>
                <p>
                  Las cookies de marketing se utilizan para rastrear a los
                  visitantes en las páginas web. La intención es mostrar
                  anuncios relevantes y atractivos para el usuario individual, y
                  por lo tanto, más valiosos para los editores y terceros
                  anunciantes.
                </p>
                <table>
                  <thead>
                    <tr>
                      <th width="200px">TITULAR DEL DOMINIO</th>
                      <th width="500px">NOMBRE</th>
                      <th width="300px">FINALIDAD</th>
                      <th width="300px">CADUCIDAD</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowspan="3">google.com</td>
                      <td>ads/ga-audiences</td>
                      <td>
                        Utilizada por Google AdWords para reconectar con
                        visitantes que tienen posibilidad de convertirse en
                        clientes, se basa en el comportamiento online a través
                        de las webs.
                      </td>
                      <td>Sesión</td>
                    </tr>
                  </tbody>
                </table>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="configuracionCookies == true" class="mb-3">
          <details class="conf-cookies-det">
            <summary>
              <span class="conf-cookies-header">
                <span class="conf-cookies-title">Funcional</span>
                <span class="conf-cookies-always-active"> Siempre activo </span>
              </span>
            </summary>
          </details>

          <details class="conf-cookies-det">
            <summary>
              <span class="conf-cookies-header">
                <span class="conf-cookies-title">Estadísticas</span>
                <span class="conf-cookies-checkbox">
                  <div class="c-switch">
                    <span
                      class="c-switch__checkbox"
                      [class.c-switch__checkbox--on]="switchActivatedEstadisticas"
                      (click)="toggleSwitchEstadisticas()"
                    >
                      <span
                        class="c-switch__thumb-wrap"
                        [class.c-switch__thumb-wrap--on]="switchActivatedEstadisticas"
                      >
                        <span class="c-switch__thumb"></span>
                      </span>
                      <p class="c-switch__on"></p>
                      <p class="c-switch__off"></p>
                    </span>
                    <input
                      class="c-switch__input"
                      type="checkbox"
                      placeholder=""
                    />
                  </div>
                </span>
              </span>
            </summary>
          </details>

          <details class="conf-cookies-det">
            <summary>
              <span class="conf-cookies-header">
                <span class="conf-cookies-title">Marketing</span>
                <span class="conf-cookies-checkbox">
                  <div class="c-switch">
                    <span
                      class="c-switch__checkbox"
                      [class.c-switch__checkbox--on]="switchActivatedMarketing"
                      (click)="toggleSwitchMarketing()"
                    >
                      <span
                        class="c-switch__thumb-wrap"
                        [class.c-switch__thumb-wrap--on]="
                          switchActivatedMarketing
                        "
                      >
                        <span class="c-switch__thumb"></span>
                      </span>
                      <p class="c-switch__on"></p>
                      <p class="c-switch__off"></p>
                    </span>
                    <input
                      class="c-switch__input"
                      type="checkbox"
                      placeholder=""
                    />
                  </div>
                </span>
              </span>
            </summary>
          </details>
        </div>
        <div class="row">
          <div class="col-12 col-xl-4 px-0 py-1" style="text-align: center">
            <button
              type="button"
              class="boton_personalizado"
              (click)="allCookies()"
            >
              Aceptar
            </button>
          </div>
          <div class="col-12 col-xl-4 px-0 py-1" style="text-align: center">
            <button
              type="button"
              class="boton_personalizado2"
              (click)="soloFuncionales()"
            >
              Solo funcionales
            </button>
          </div>
          <div
            class="col-12 col-xl-4 px-0 py-1"
            style="text-align: center"
            *ngIf="configuracionCookies == false"
          >
            <button
              type="button"
              class="boton_personalizado2"
              (click)="AbrirConfiguracion()"
            >
              Configuración
            </button>
          </div>
          <div
            class="col-12 col-xl-4 px-0 py-1"
            style="text-align: center"
            *ngIf="configuracionCookies == true"
          >
            <button
              type="button"
              class="boton_personalizado2"
              (click)="GuardarCookiesPersonalizadas()"
            >
              Guardar preferencias
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</aig-modal>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor() { }

  getPreferredLanguage(): string {
    // Obtiene la información del idioma del navegador del usuario
    let language = navigator.languages ? navigator.languages[0] : (navigator.language);
    if (navigator.languages.filter(l => l.length > 3)[0]) {
      language = navigator.languages.filter(l => l.length > 3)[0];
    }

    // El primer idioma en la lista suele ser el idioma preferido del usuario
    if (language && language.length > 0) {
      return language;
    } else {
      // Si no se puede obtener el idioma del navegador, devolvemos un idioma por defecto
      return 'es-ES'; // Por ejemplo, español
    }
  }

}
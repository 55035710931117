import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { LandingPageComponent } from './modules/landing/pages/landing-page/landing-page.component';


const routes: Routes = [
  {
    path: 'seguro',
    loadChildren: () => import('./modules/insurance/insurance.module').then(m => m.InsuranceModule)
  },
  {
    path: '',
    component: LandingPageComponent,
    loadChildren : () => import('./modules/landing/landing.module').then(m => m.LandingModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration : 'enabled', preloadingStrategy: NoPreloading} )],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';

declare let dataLayer : any; //Declarar dataLayer globalmente

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {

  constructor() { }

  public pushEvent(event: any) {
    if (dataLayer) {
      dataLayer.push(event);
    } else {
      console.error('DataLayer is not defined.');
    }
  }
}

import { Action } from "@ngrx/store";

export enum LanguageActionTypes {
    GetPreferredLanguage = '[Language] Get Preferred Language',
    SetPreferredLanguage = '[Language] Set Preferred Language'
  }
  
  export class GetPreferredLanguage implements Action {
    readonly type = LanguageActionTypes.GetPreferredLanguage;
  }
  
  export class SetPreferredLanguage implements Action {
    readonly type = LanguageActionTypes.SetPreferredLanguage;
    constructor(public payload: string) {
        
    }
  }
  
  export type LanguageActions = GetPreferredLanguage | SetPreferredLanguage;
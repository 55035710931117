


import { AccionesUsuario, SET_USER, UNSET_USER} from './auth.actions';
import { IUser } from '../IUser';


export interface AuthState {
  usuario: IUser; 
  
}

const initState: AuthState = {
  usuario: null,
  
};

export function authReducer( state = initState, action: AccionesUsuario ): AuthState {

    switch ( action.type ) {
        case SET_USER:
            return {
              usuario: { ... action.usuario }
          };

        case UNSET_USER:
            return {
              usuario: null
          };

        default:
           return state;
    }

}





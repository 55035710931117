<div class="aig-main">

  <!-- #REGION BANNER  -->
  <div class="aig-main__banner u-pos-relative">
    <picture>
      <source media="(min-width: 992px)" rel="preload" srcset="./assets/images/hero/aig_banner.webp" >
      <source media="(min-width: 576px)" rel="preload" srcset="./assets/images/hero/aig_banner_tablet.webp" >
      <img class="banner" rel="preload" src="./assets/images/hero/aig_banner_mb.webp" alt="AIG imagen de portada"  fetchpriority="high"/>
    </picture>

    <div class="aig-main__moreinfo p-flexss u-dir-col u-color-primary1 u-height-content@xl u-size-content@xl">
      <h1 class="title u-m-0">
          <strong>
          {{"HOME.T1" | translate  }}
          </strong>
        </h1>

      <h2 class="u-fs-28 u-family-aig u-fw-500 u-mt-3">
        {{"HOME.T2" | translate  }}
      </h2>
      <ul class="u-my-3">
        <li *ngFor="let item of info" class="p-flexcs u-fs-18 u-mb-1">
          <i class="icon-tick u-fs-24 u-me-1"></i>
          {{ item.desc }} </li>
      </ul>

      <button
          (click)="onCalculaCuota($event, '/seguro/datos-contacto')"
          class="aig-main__gotoform e-btn--primary e-btn--extrap u-radius-circle u-p-2 u-fw-500" 
          name="calc-cuota"
          id="calc-cuota1">
          {{"HOME.T6" | translate  }}
      </button> 



    </div>
  </div> 
   <!-- #ENDREGION BANNER  -->


   <!-- #REGION CONTENT  -->
  <div class="aig-main__content" #detail>

    <div class="aig-main__section o-container">
      <div class="col-12 u-px-0">
        <div class="u-text-center u-my-5">
          <h2 class="u-color-primary1 title u-family-aig"> {{"HOME.T7" | translate  }} </h2>
          <p class="u-fw-400">
            {{"HOME.T8" | translate  }}
          </p>
        </div>
      </div>
    </div>

    <div class="aig-main__section o-container">
      <div class="col-12 u-px-0">
        <div class="u-text-center u-my-5">
          <h2 class="u-color-primary1 title u-family-aig"> {{"HOME.T9" | translate  }} </h2>
          <p class="u-fw-400">
            {{"HOME.T10" | translate  }}
          </p>
        </div>
      </div>
    </div>

    <!-- #REGION ACCORDION  -->
    <div class="aig-main__section o-container">
      <div class="aig-main__section-head p-flexcs u-dir-col">
        <h2 class="u-family-aig title">
          <strong>
            {{"HOME.T11" | translate  }}
          </strong>
        </h2>
        <aig-accordion [items]="coverages" prefix="cov"></aig-accordion>
      </div>
    </div>
   <!-- #ENDREGION ACCORDION  -->

    <!-- #REGION POLICY ADVANTAGES  -->
    <div class="aig-main__section o-container">
      <div class="row u-mx-0">
        <div class="col-12 col-sm-12 col-lg-5 col-xl-5 u-mb-4@xs">
          <picture>
            <source rel="preload"  media="(min-width: 992px)" srcset="../../../assets/images/main/main1.webp" >
            <source rel="preload" media="(min-width: 576px)" srcset="../../../assets/images/main/main1_tablet.webp">
            <img rel="preload"  class="u-size-per-100" src="../../../assets/images/main/main1_mb.webp" alt="image"  style="margin-block: 1rem;">
          </picture>
        </div>
        <div class="col-12 col-sm-12 col-lg-7 col-xl-7 u-px-3">
          <div class="u-text-center@xs">
            <strong class="u-family-aig title"> {{"HOME.T12" | translate  }} </strong>
            <p class="u-fw-400">
              {{"HOME.T13" | translate  }}
            </p>
          </div>
          
          <div class="aig-main__advantages col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 u-px-0 u-pos-relative u-ds-flex u-dir-col u-mt-2@xs u-mt-2@sm u-mt-2@md">
            <div class="u-mt-4" *ngFor="let item of pack, last as l;">
              <p class="u-color-primary1 u-fs-18 u-fw-400 u-ds-flex u-dir-col">
                <span class="u-color-inherit u-fs-18 u-mb-2">
                  <i class="icon-tick u-fs-24 u-me-1"></i> 
                  {{ item.desc | translate}}</span>
                <span class="u-color-secondary u-fs-16 u-fw-400">
                  {{ getAdditionalContent(item) |translate }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- #ENDREGION POLICY ADVANTAGES  -->

    <div class="aig-main__section o-container">
      <div class="col-12 u-px-0">
        <div class="u-text-center u-my-5">
          <h2 class="u-color-primary1 title u-family-aig"> {{"HOME.T14" | translate  }} </h2>
          <p class="u-fw-400 u-mb-5">
            {{"HOME.T15" | translate  }}
          </p>
          <div class="aig-main__tooltip u-text-center u-size-per-100">
            <p class="u-color-primary1"> 
              <span class="u-family-aig u-fs-24 u-fw-500 u-color-primary1"> {{"HOME.T16" | translate  }}  </span>
            </p>
            <span class="aig-main__tooltip-beak"></span>
          </div>
          <button
              (click)="onCalculaCuotaBody($event, '/seguro/datos-contacto')"
              class="e-btn--primary e-btn--extrap u-radius-circle u-p-2 u-m-centered u-fw-500" 
              name="calc-cuota"
              id="calc-cuota">
              {{"HOME.T6" | translate  }}
          </button>
        </div>
      </div>
    </div>

    <!-- #REGION ACCORDION  -->
    <div class="aig-main__section o-container">
      <div class="aig-main__section-head p-flexcs u-dir-col">
        <h2 class="u-family-aig title">
          <strong>
            {{"HOME.T17" | translate  }}
          </strong>
        </h2>
        <aig-accordion [items]="faqs" prefix="faqs"></aig-accordion>
      </div>
    </div>
   <!-- #ENDREGION ACCORDION  -->

   <div class="aig-main__section o-container u-ds-flex u-dir-col">
    <div class="u-size-per-100 p-flexcs u-dir-col u-mb-8">
      <div class="u-text-center u-my-5">
        <h2 class="u-color-primary1 u-family-aig title"> {{"HOME.T18" | translate  }} </h2>
        <p class="u-fw-400">
          {{"HOME.T19" | translate  }}
        </p> 
      </div>
    </div>

    <div class="aig-main__jumborow u-size-per-100  u-ds-flex">
      <div *ngFor="let item of moreinfo; even as e; odd as o" 
        class="aig-main__jumbotron" [class.aig-main__jumbotron--even]="e"
        [class.aig-main__jumbotron--odd]="o">
        <div class="c-jumbotron p-flexcc u-dir-col u-height-per-100">
          <div class="aig-main__icon u-mb-3">
            <i [class]="item.icon" class="u-fs-24 u-color-primary1"></i>
          </div>
          <p class="u-text-center" [innerHTML]="item.desc"></p>
        </div>
      </div>
    </div>
  </div>
   <!-- #ENDREGION CONTENT  -->

</div>
 
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, isDevMode } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { appReducers, metaReducers, arrEffects } from "./app.reducer";
import { WINDOW_PROVIDERS } from "./core/services/window.service";
import { AuthGuardService } from "./core/auth/auth-guard.service";
import { environment } from "src/environments/environment";
import { HttpErrorInterceptor } from "./core/services/httpError.interceptor";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LayoutModule } from "./core/layout/layout.module";
import { SelectfieldModule } from "./shared/components/selectfield/selectfield.module";
import { ToastMsgModule } from "./shared/components/toast-msg/toast-msg.module";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { NgxMaskModule } from "ngx-mask";
import { ServiceWorkerModule } from "@angular/service-worker";
import { CookieService } from "ngx-cookie-service";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';

export function httpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, "./i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SelectfieldModule,
    ToastMsgModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    StoreModule.forRoot(appReducers, { metaReducers }),
    EffectsModule.forRoot(arrEffects),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    LayoutModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot({
      showMaskTyped: true,
      // clearIfNotMatch : true
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    MatAutocompleteModule,
    MatInputModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "outline" },
    },
    WINDOW_PROVIDERS,
    AuthGuardService,
    CookieService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService {

  baseUrl = environment.DOMAIN;
  public idSolicitud = 0;  
  public formulario: FormGroup;
  
  constructor(private _fb: FormBuilder,private http:HttpClient) {
    this.initForm();
    
    if(this.obtenerFormularioSesion()){
      var json = JSON.parse(this.obtenerFormularioSesion());

      if(json.dos_titulares){
        this.activarValidadoresSegundoTitular();
      }

      if(json.Hijos.length > 0 && json.Hijos.length <= 9){
        json.Hijos.forEach(() => {
          this.addHijo()
        });
      }
      this.formulario.setValue(json);
    }
   }
  
   public obtenerFormularioSesion(){
   
     
    if(sessionStorage.getItem("form")){
      var json = JSON.parse(sessionStorage.getItem("form"));
      this.formulario.setValue(json);
    }

    return sessionStorage.getItem("form");
   }

   noNumericoValidator() {
    return (control) => {
      const forbidden = /\d/.test(control.value);
      return forbidden ? { 'numeric': { value: control.value } } : null;
    };
  }

   public borrarFormularioSesion(){
    this.initForm();
    this.borrarIdSolicitud();
    return sessionStorage.removeItem("form");
   }

   public guardarFormularioSesion(){
    return sessionStorage.setItem('form', JSON.stringify(this.formulario.getRawValue()));
   }

   public obtenerIdSolicitud(){
    if(sessionStorage.getItem("IdSolicitud")){
      this.idSolicitud = +sessionStorage.getItem("IdSolicitud");
    }
     return this.idSolicitud;
   }

   public borrarIdSolicitud(){
    return sessionStorage.removeItem("IdSolicitud");
   }

   public guardarIdSolicitud(idSolicitud:number){
    this.idSolicitud = idSolicitud;
    return sessionStorage.setItem('IdSolicitud', this.idSolicitud.toString());
   }

   
   initForm(){
    
    


    this.formulario = this._fb.group({
      telefono: new FormControl("", [Validators.required]),
      fecha_nacimiento: new FormControl("", [Validators.required, this.fechaNacimientoValidatorPadres()]),
      consentimiento: [false],
      dos_titulares: new FormControl(false),
      fecha_nacimiento_2titular: new FormControl(null, []),//Los validadores se ponen dinamicamente en contact-data-page
      confirmacion: new FormControl(false, Validators.requiredTrue),
      tiene_hijos:new FormControl(false),
      capacidad:new FormControl(undefined, Validators.requiredTrue),
      CONFIRMACION_CONTRATO:new FormControl(false, Validators.requiredTrue),
      Nombre:new FormControl("", [Validators.required , Validators.maxLength(35)]),
      Apellidos: new FormControl("", [Validators.required , Validators.maxLength(35)]),
      prefijo: new FormControl("+34"),
      Email: new FormControl("", [Validators.required, Validators.email, Validators.maxLength(35)]),
      num_documento: new FormControl("", [Validators.required, Validators.maxLength(9), this.dniNieValidator()]),
      direccion: new FormControl("", [Validators.required, Validators.maxLength(105)]),
      bloque: new FormControl("", [Validators.maxLength(5)]),
      piso: new FormControl("", [ Validators.maxLength(5)]),
      puerta: new FormControl("", [Validators.maxLength(5)]),
      provincia: new FormControl("", [Validators.required , Validators.maxLength(35)]),
      codigo_postal: new FormControl("", [Validators.required, Validators.maxLength(5),Validators.maxLength(5)]),
      poblacion: new FormControl("", [Validators.required , Validators.maxLength(35)]),
      pais:  new FormControl('España', [Validators.required, Validators.minLength(1) , Validators.maxLength(15)]),
      iban: new FormControl("", [Validators.required , Validators.maxLength(30), this.ibanValidator()]),
      tipo_poliza: new FormControl(-1, Validators.required),
      Titular2: new FormControl(null),
      Hijos: this._fb.array([]),
      importe: new FormControl(0),
      plan_seleccionado: new FormControl(""),
      codigo_pais: new FormControl("ES"),
      genero: new FormControl(null, Validators.required),
      codigo_poblacion: new FormControl("")
    });
    
    return this.formulario;
  }


  private createHijoFormGroup(): FormGroup {
    return this._fb.group({
      nombre: ['', Validators.required],
      apellidos: ['', Validators.required],
      num_documento: ['', [Validators.maxLength(9), this.dniNieValidator()]],
      fecha_nacimiento: ['',[ Validators.required,this.fechaNacimientoValidatorHijos()]],
    });
  }

  

  get hijosFormArray(): FormArray {
    var hijos = this.formulario.get('Hijos');
    return hijos as FormArray;
  }

  public addHijo(): void {
    if(this.hijosFormArray.controls.length <= 9){
      this.hijosFormArray.push(this.createHijoFormGroup());
    }
  }

  eliminarHijo(index: number): void {
    this.hijosFormArray.removeAt(index);
  }

  activarValidadoresSegundoTitular(){
    const Titular2 = this._fb.group({
      Nombre: new FormControl(""),//Verificar la obligatoriedad cuando tienes o no segundo titular.
      Apellidos: new FormControl(""),
      pais: new FormControl(""),
      prefijo: new FormControl(""),
      telefono: new FormControl(""),
      direccion: new FormControl("" ),
      poblacion: new FormControl("" ),
      provincia: new FormControl("" ),
      codigo_postal: new FormControl("",),
      num_documento: new FormControl(""),
      fecha_nacimiento: new FormControl(undefined),
      genero: new FormControl(null)
    });
    this.formulario.removeControl('Titular2')
    this.formulario.addControl('Titular2',Titular2)
    this.activarValidadores('Titular2.Nombre',[Validators.required,Validators.minLength(1)]);
    this.activarValidadores('Titular2.Apellidos',[Validators.required,Validators.minLength(1)]);
    this.activarValidadores('Titular2.pais',[Validators.required,Validators.minLength(1)]);
    this.activarValidadores('Titular2.direccion',[Validators.required,Validators.minLength(1)]);
    this.activarValidadores('Titular2.poblacion',[Validators.required,Validators.minLength(1)]);
    this.activarValidadores('Titular2.provincia',[Validators.required,Validators.minLength(1)]);
    this.activarValidadores('Titular2.codigo_postal',[Validators.required,Validators.maxLength(5),Validators.minLength(5)]);
    this.activarValidadores('Titular2.num_documento',[Validators.required, Validators.maxLength(9), this.dniNieValidator()]);
    this.activarValidadores('Titular2.fecha_nacimiento',[Validators.required]);
    this.activarValidadores('Titular2.genero',[Validators.required]);
  }

  desactivarValidadoresSegundoTitular(){
    this.formulario.removeControl('Titular2')
    this.formulario.addControl('Titular2',new FormControl(null))
    // this.desactivarValidadores('Titular2.Nombre');
    // this.desactivarValidadores('Titular2.Apellidos');
    // this.desactivarValidadores('Titular2.pais');
    // this.desactivarValidadores('Titular2.direccion');
    // this.desactivarValidadores('Titular2.provincia');
    // this.desactivarValidadores('Titular2.codigo_postal');
  }

  activarValidadores(campo:string,validadores:ValidatorFn[]){
    this.formulario.get(campo).setValidators(validadores);
    this.formulario.get(campo).markAsTouched();
    setTimeout(() => {
      this.formulario.updateValueAndValidity();
    }, 1);
  }

  desactivarValidadores(campo:string){
    this.formulario.get(campo).setValidators(null);
    this.formulario.get(campo).markAsTouched()
    setTimeout(() => {
      this.formulario.updateValueAndValidity();
    }, 1);
  }




  public postFinalizarEnfermedades (id:number, body:any){
    return this.http.post(`${this.baseUrl}${environment.API.FINALIZARENFERMEDADES}?Id=${id}`,body);
  }

  // public postDatosWebEnfermedades(datos:IDatos) {
  //   return this.http.post(`${this.baseUrl}${environment.API.DATOSWEBENFERMEDADES}`,datos);
  // }

  dniNieValidator(): ValidatorFn {
    console.log('dniNieValidator');
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value: string = control.value;
      const validFormats = /^[XYZxyz]?\d{7,8}[A-Za-z]$/;
      const niePrefixes = ['X', 'Y', 'Z'];
      if(!value){
        return null;
      }
      // Verifica si el formato del DNI/NIE es válido
      if (!validFormats.test(value)) {
        return { 'formatoInvalido': true };
      }
  
      // Normaliza el valor eliminando el prefijo y convirtiendo a mayúsculas
      let normalizedValue = value.toUpperCase();
      if (niePrefixes.includes(normalizedValue.charAt(0))) {
        normalizedValue = '0' + normalizedValue.substr(1);
      }
  
      // Extrae el número y la letra de control del valor normalizado
      const numberPart = normalizedValue.slice(0, -1);
      const letterPart = normalizedValue.slice(-1);
      const controlLetter = 'TRWAGMYFPDXBNJZSQVHLCKE';
      const index = parseInt(numberPart, 10) % 23;
      const expectedLetter = controlLetter.charAt(index);
  
      // Verifica si la letra de control es correcta
      if (letterPart !== expectedLetter) {
        return { 'letraInvalida': true };
      } else {
        return null; // Válido
      }
  
    };
  }


  getValorLetra(letra) {
    const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return letras.search(letra) + 10;
  }

  ValidateIBAN(ibanConLetra)
  {
    const letraE = this.getValorLetra('E').toString();
    const letraS = this.getValorLetra('S').toString();
    const iban = letraE + letraS + ibanConLetra.substring(2);

    // Movemos los 6 primeros numeros al final de la cadena
    const ibanReordenado = iban.substring(6) + iban.substring(0,6);

    //Se calcula el resto, llamando a la función modulo97, definida más abajo
    let resto = this.modulo97(ibanReordenado);
    if (resto == "1"){
        return true;
    }else{
        return false;
    }

  }

  modulo97(iban) {
    var parts = Math.ceil(iban.length/7);
    var remainer = "";
  
    for (var i = 1; i <= parts; i++) {
        remainer = String(parseFloat(remainer+iban.substr(( i - 1) * 7, 7)) % 97);
    }
  
    return remainer;
  }

  ibanValidator() {
    return (control) => {
      
      if (!control.value.toUpperCase().startsWith('ES') && (control.value.length > 0)) {
        control.value = "ES" + control.value;
      }

      const value: string = control.value;

      const pattern = /^[A-Za-z]{2}\d{22}$/; // Expresión regular para validar IBAN español

      if (!pattern.test(value)) {
        return { 'invalidIban': true }; // Inválido
      } else if (!this.ValidateIBAN(value)) {
        return { 'invalidIban': true }; // Inválido;
      } else {
        return null; // Válido
      }
    };
  }

  fechaNacimientoValidatorPadres() {
    return (control) => {

      if(!control.value) {
        return null;
      }
      const value: Date = new Date(control.value);
      const today = new Date();
      const minDate = new Date(today.getFullYear() - 49, today.getMonth(), today.getDate());
      const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

      if (value >= minDate && value <= maxDate) {
        return null; // Válido
      } else {
        return { 'edadInvalida': true }; // Inválido
      }
    };
  }

  fechaNacimientoValidatorHijos() {
    return (control) => {
      const value: Date = new Date(control.value);
      const today = new Date();
      const minDate = new Date(today.getFullYear() - 23, today.getMonth(), today.getDate());
      const maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

      if (value >= minDate && value <= maxDate) {
        return null; // Válido
      } else {
        return { 'edadInvalida': true }; // Inválido
      }
    };
  }

  

}

import { AfterViewInit, Component } from "@angular/core";
import { LanguageService } from "./shared/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs/internal/Observable";
import { Store, select } from "@ngrx/store";
import { AppState } from "./app.reducer";
import { SetPreferredLanguage } from "./app.actions";
import { AppService } from "./app.service";

@Component({
  selector: "aig-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [AppService]
})
export class AppComponent implements AfterViewInit {
  tema: string;
  preferredLanguage$: Observable<string>;
  public siteKey = "6LfPGqUpAAAAABfFi_ty53_57U-t-Nr2gvUOgO8D";

  constructor(private store: Store<AppState>,
    private languageService: LanguageService,
    private translate: TranslateService,
    public appService: AppService,
  ) {

    this.translate.setDefaultLang('es-ES');
  }

  ngOnInit(): void {
    const preferredLanguage = this.languageService.getPreferredLanguage();
    this.store.dispatch(new SetPreferredLanguage(preferredLanguage));
    this.preferredLanguage$ = this.store.pipe(select(state => state.lang.preferredLanguage));

    this.preferredLanguage$.subscribe(lang => {
      if (!lang) {
        // Si no se ha seleccionado un idioma, establece un idioma por defecto aquí
        this.store.dispatch(new SetPreferredLanguage('es-ES')); // 
      }
      else
      {
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
      }
      
    });
  }
  changeLanguage(language: string): void {
    this.store.dispatch(new SetPreferredLanguage(language));
  }
  ngAfterViewInit(): void {
   
  }
}

import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { UnsetUserAction } from './store/auth.actions';


@Injectable()
export class AuthGuardService {

  autenticate: boolean = true;
  constructor(private store: Store<AppState>, private router: Router) {
    this.store.select('usuario').subscribe(usuario => {
      if(usuario.usuario) {
        this.autenticate = usuario.usuario.AUTENTIFICADO;
      }
    });
  }

  canActivate(): any {
    let response = false;
    if (this.autenticate) {
      return true;
    } else {
        this.store.dispatch(new UnsetUserAction())
        this.router.navigate(['/login']);
    }
    return response;
  }

}

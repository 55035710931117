<!-- #REGION HEADER -->
<header class="aig-header">
  <div class="aig-header__container u-py-2">
    <div class="p-flexcb">
      <div class="aig-header__block u-gap-8">
        <a class="aig-header__aig clickable" (click)="onHomeClick($event, '/')">
          <img rel="preload" class="u-size-per-100 logo_cabecera a" src="./assets/images/logo/logo.svg" alt="logo_aig"  />
        </a>
        <div class="p-flexcs u-gap-24">
            <a class="aig-header__cetelem clickable" (click)="onCetelemLinkClick($event, 'https://www.cetelem.es/')" rel="noopener">
              <img  rel="preload" src="./assets/images/logo/cetelen.webp" alt="logo_cetelem" width="73%" >
            </a>
          <div class="p-flexcs u-gap-24">
            <a class="aig-header__span clickable" (click)="onTelefonoLinkClick($event, translatedPhoneNumber)" role="button" id="tel-link" aria-label="telefono">
              <i class="icon-phone u-me-2"></i>
              <p class="u-ds-none@xs u-ds-none@sm u-ds-none@md">{{"HEADER.PHONE" |translate}}</p>
              <p class="u-ds-none@xs u-ds-none@sm u-ds-none@md"></p>
            </a>
            <div class="aig-header__span u-ds-none@xs u-ds-none@sm u-ds-none@md" role="button" aria-label="llamame" (click)="showModalCall()"
              id="tellamamos-link">
              <i class="icon-phone-black-screen u-me-2"></i>
              <p> {{"HEADER.CALL" |translate}} </p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- #ENDREGION -->

<!-- #REGION MODAL LLAMAR -->
<aig-modal *ngIf="modalCall" position="center">
  <div class="u-p-5 u-border-bottom row u-mx-0" header>
    <div class="col-12">
      <h2 class="u-fs-40 u-color-primary1 u-fw-500">{{"HEADER.CALL" |translate}}</h2>
    </div>
  </div>
  <form [formGroup]="callForm" class="u-mx-0 u-p-5" body>
    <div class="row u-mx-0">
      <div class="col-12 u-mb-3">
        <p class="u-color-secondary1 u-fs-16 u-fw-400">
          {{"HEADER.T1" |translate}}
        </p>
      </div>
      <div class="col-12 col-sm-12 col-md-6 u-mb-3">
        <div class="e-field">
          <label class="e-field__label e-field__label--upside"> {{"HEADER.NAME" |translate}}<span>*</span> </label>
          <input type="text" class="e-field__input u-ps-2 u-py-1 form-control" id="name" autocomplete="on"
            placeholder="" formControlName="nombre" />
          <span class="e-field__error" *ngIf="getFormularioError('nombre')">
            Error de prueba
          </span>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 u-mb-3">
        <div class="e-field">
          <label class="e-field__label e-field__label--upside"> {{"HEADER.SURNAME" |translate}}<span>*</span> </label>
          <input type="text" class="e-field__input u-ps-2 u-py-1 form-control" id="surname" autocomplete="on"
            placeholder="" formControlName="apellidos" />
          <span class="e-field__error" *ngIf="getFormularioError('apellidos')">
            {{'ERRORS.FAIL' | translate }}
          </span>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 u-mb-3">
        <div class="e-field">
          <label class="e-field__label e-field__label--upside">{{"HEADER.TELEPHONE" |translate}}<span>*</span>
          </label>
          <mat-form-field appearance="outline">
            <mat-label  class="e-field__label e-field__label--upside">(+34)</mat-label>
            <input matInput type="text" class="e-field__input u-ps-2 u-py-1 form-control" id="phone" autocomplete="on"
            mask="000 00 00 00" dropSpecialCharacters="true" pattern="((6|7|8|9)[1-9]\d{1})\s\d{2}\s\d{2}\s\d{2}"
                formControlName="telefono" matNativeControl/>
            <mat-error class="e-field__error" *ngIf="getFormularioError('telefono')">
              {{'ERRORS.FAIL_PHONE' | translate }}
            </mat-error>
          </mat-form-field>
          <!-- <label class="e-field__label e-field__label--upside"> <span>*</span> </label>
          <input type="tel" class="e-field__input u-ps-2 u-py-1 form-control" id="phone" autocomplete="on"
            placeholder="(+34)" formControlName="telefono" />
          <span class="e-field__error" *ngIf="false">
            Error de prueba
          </span> -->
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 u-mb-3">
        <div class="e-field">
          <label class="e-field__label e-field__label--upside"> {{"HEADER.TIME" |translate}}<span>*</span>
          </label>
          <div class="e-field__input--select u-pos-relative">
            <select class="e-field__input" name="time-range" id="time-range" formControlName="horario"
              title="time-range">
              <option value="mañana">9h a 15h</option>
              <option value="tarde">15h a 21h</option>
              <option value="ambas">Indiferente</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 u-mb-3">
        <label class="u-ms-2 p-flexss u-cursor-pointer" for="consent">
          <input class="e-check u-me-2" formControlName="consentimiento" type="checkbox" id="consent" placeholder="consentimiento"
            name="consent" value="consentimiento">
          <span class="u-color-black u-fs-16 " [innerHTML]="'HEADER.CHECK' |translate">
          </span>
        </label>
      </div>
    </div>
  </form>
  <div class="u-border-top u-mx-0 u-p-5 p-flexcb u-gap-24" buttons>
    <button class="e-btn--secondary e-btn--rounded e-btn--extrap u-flex-1 u-fs-16 u-fw-600" type="button"
      (click)="modalCall = false" id="tellamamos-cancelar">
      {{"HEADER.CANCEL" |translate}}
    </button>
    <button class="e-btn--primary e-btn--rounded e-btn--extrap u-fs-16 u-flex-1 u-fw-600" type="button"
      [disabled]="!callForm.valid" (click)="submitForm()" id="tellamamos-aceptar">
      {{"HEADER.SEND" |translate}}
    </button>
  </div>
</aig-modal>
<!-- #ENDREGION -->

<!-- #REGION MODAL CONFIRM-->
<aig-modal *ngIf="modalConfirmation" position="center" customMaxWidth="400px">
  <div class="p-flexcc u-dir-col u-p-5" body>
    <div class="u-mb-4">
      <img src="../../../../assets/images/logo/logo.svg" alt="AIG_logo">
    </div>
    <p class="u-color-secondary1 u-fs-18 u-fw-400 u-text-center">
      {{"HEADER.T2" |translate}}
    </p>
  </div>
</aig-modal>
<!-- #ENDREGION-->
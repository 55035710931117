import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/app.reducer";
import { TranslateService } from "@ngx-translate/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ICallMeLater } from "src/app/modules/insurance/interfaces/iCallMeLater";
import { environment } from "@environments/environment";
import { GoogleTagManagerService } from "../../services/google-tag-manager.service";
import { Router } from "@angular/router";

@Component({
  selector: "aig-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public modalCall: boolean = false;
  public modalConfirmation: boolean = false;
  public callForm: FormGroup;
  baseUrl = environment.DOMAIN;
  public customPatterns = {
    "0": { pattern: new RegExp("/^[679]{1}[0-9]{8}$/") },
  };
  translatedPhoneNumber: string;

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
    private _fb: FormBuilder,
    private http: HttpClient,
    private gtmService: GoogleTagManagerService,
    private router: Router
  ) {
    this.callForm = this._fb.group({
      nombre: new FormControl("", Validators.required),
      apellidos: new FormControl("", Validators.required),
      telefono: new FormControl("", [Validators.required]),
      horario: new FormControl("", Validators.required),
      consentimiento: new FormControl(false, Validators.requiredTrue),
    });

    this.translate.get("HEADER.PHONECALL").subscribe((translation: string) => {
      this.translatedPhoneNumber = translation;
    });
  }

  ngOnInit() {
    this.store.select("lang").subscribe((lang) => {
      this.translate.setDefaultLang(lang.preferredLanguage);
      this.translate.use(lang.preferredLanguage);
    });

    this.callForm.valueChanges.subscribe(() => {});
  }

  getFormularioError(campo: string) {
    return (
      this.callForm.get(campo).touched &&
      this.callForm.get(campo).dirty &&
      this.callForm.get(campo).invalid
    );
  }

  public updateForm() {}

  public dataFormBehavior() {}

  public showModalCall(): void {
    // Emitir el evento al dataLayer de GTM
    this.gtmService.pushEvent({
      event: "cta",
      event_detail: "call_header",
    });

    this.modalCall = true;
  }

  public submitForm(): void {
    // Emitir el evento al dataLayer de GTM
    this.gtmService.pushEvent({
      event: "cta",
      event_detail: "call_send_form",
    });

    if (this.callForm.valid) {
      this.postDatos(this.callForm.getRawValue()).subscribe(() => {
        this.modalCall = false;
        this.modalConfirmation = true;
        setTimeout(() => {
          this.modalConfirmation = false;
        }, 3000);
      });
    }
  }

  public postDatos(datos: ICallMeLater) {
    return this.http.post(
      `${this.baseUrl}${environment.API.LLAMAME_WEB_ENFERMEDADES}`,
      datos
    );
  }

  //Eventos interacción para medir

  onHomeClick(event: Event, path: string) {
    event.preventDefault(); // Prevent the default behavior

    // Emitir el evento al dataLayer de GTM
    this.gtmService.pushEvent({
      event: "cta",
      event_detail: "logo_aig",
    });

    // Navegar después de un pequeño retraso para asegurar que el evento GA se envíe
    setTimeout(() => {
      this.router.navigate([path]);
    }, 300);
  }
  onCetelemLinkClick(event: Event, url: string) {
    event.preventDefault(); // Prevent the default behavior

    // Emitir el evento al dataLayer de GTM
    this.gtmService.pushEvent({
      event: "cta",
      event_detail: "logo_cetelem",
    });

    // Navegar después de un pequeño retraso para asegurar que el evento GA se envíe
    setTimeout(() => {
      window.open(url, "_blank");
    }, 300);
  }

  onTelefonoLinkClick(event: MouseEvent, phoneNumber: string) {
    event.preventDefault(); // Prevent the default behavior

    let urlRouter = this.router.url;

    if (urlRouter == "/") { // Landing Principal
      // Emitir el evento al dataLayer de GTM
      this.gtmService.pushEvent({
        event: "cta",
        event_detail: "phone_header",
      });
    } else if (urlRouter == "/seguro/seleccionar-plan") { //Selecciona tu plan
      // Emitir el evento al dataLayer de GTM
      this.gtmService.pushEvent({
        event: "planes",
        event_detail: "llamar",
      });
    } else if (urlRouter == "/seguro/completar-datos") {  //Introduce datos
      // Emitir el evento al dataLayer de GTM
      this.gtmService.pushEvent({
        event: "introduccion_datos",
        event_detail: "llamar",
      });
    }

    // Llamar después de un pequeño retraso para asegurar que el evento GA se envíe
    setTimeout(() => {
      window.location.href = `tel:${phoneNumber}`;
    }, 300);
  }
}

<div class="aig-footer u-bg-secondary2 u-color-white u-size-per-100 p-flexcc">
  <div class="o-container-lg u-size-inherit">
    <div class="row u-mx-0">
      <div class="aig-footer__col col-12 u-mt-aig" [innerHTML]="'FOOTER.T1' | translate">
      </div>
    </div>
  </div>
</div>

<!-- #REGION MODAL LLAMAR -->

  <aig-modal-cookies>
  </aig-modal-cookies>

<!-- #ENDREGION -->



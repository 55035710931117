import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'aig-modal-cookies',
  templateUrl: './modal-cookies.component.html',
  styleUrls: ['./modal-cookies.component.scss']
})
export class ModalCookiesComponent implements OnInit {

  modal: boolean = false;
  preferencias: boolean = false;

  checked: boolean= true;
  cultura: any;

  cookieValue: string;

  cookiesCompletas: boolean = false;
  configuracionCookies: boolean = false;
  public switchActivatedEstadisticas: boolean = false;
  public switchActivatedMarketing: boolean = false;
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private cookieService: CookieService, private router: Router) {
    this.cookieValue = this.cookieService.get('Funcional');
    this.modal = this.cookieValue === "allow" ? false : true;
  }

  ngOnInit() {
    this.cookieValue = this.cookieService.get('Funcional');
    this.modal = this.cookieValue == "allow" ? false : true;
  }

  mostrarModal(){
    this.modal = true;
  }

  ngOnChange(){
    this.cookieValue = this.cookieService.get('Funcional');
    this.modal = this.cookieValue == "allow" ? false : true;
  }

  allCookies() {
    this.modal = false;
    if (this.modal) {
      this.close.emit(null);
    }
    this.cookieService.set('Funcional', 'allow');
    this.cookieValue = this.cookieService.get('Funcional');
    this.cookieService.set('Estadisticas', 'allow');
    this.cookieService.set('Marketing', 'allow');
  }

  soloFuncionales() {
    this.modal = false;
    if (this.modal) {
      this.close.emit(null);
    }
    this.cookieService.set('Funcional', 'allow');
    this.cookieValue = this.cookieService.get('Funcional');
  }

  GuardarCookiesPersonalizadas() {
    this.modal = false;
    if (this.modal) {
      this.close.emit(null);
    }
    this.cookieService.set('Funcional', 'allow');
    this.cookieValue = this.cookieService.get('Funcional');
    if (this.switchActivatedEstadisticas) {
      this.cookieService.set('Estadisticas', 'allow');
    }
    if (this.switchActivatedMarketing) {
      this.cookieService.set('Marketing', 'allow');
    }
  }

  navegar(url: string) {
    const ruta = this.router.url.split('/');
    if (!this.cultura || this.cultura === "")
      this.cultura = ruta[1];
    this.router.navigate([[this.cultura] + '/anonymous-pages/' + url]);
  }

  mostrarCookiesCompletas() 
  {
    this.cookiesCompletas = true;
  }

  AbrirConfiguracion() {
    this.configuracionCookies = true;
  }

   toggleSwitchEstadisticas(): void {
    this.switchActivatedEstadisticas = !this.switchActivatedEstadisticas;
    
  }

  toggleSwitchMarketing(): void {
    this.switchActivatedMarketing = !this.switchActivatedMarketing;
  }
}



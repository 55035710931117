export const environment = { 
  production: false,
  CRYPTO_KEY: "4SLDKTE235AS65SH",
  CRYPTO_VI: "6DFG6SHFS6SDF2SD",
  DOMAIN:"https://perseo-seguros-i2cs-cetelem.icp.es/api/I2CS",
  API: {
    GUARDAR_DATOS_WEB: "/Web/GuardarDatosWeb",
    LLAMAME_WEB_ENFERMEDADES: "/Web/LlamameWebEnfermedades",
    ENVIAR_DATOS: "/Web/GuardarDatos",
    COMPROBAR_IBAN: "/Web/CheckIBAN",
    GETTARIFAS:"/Web/GetCetelemEnfermedadesTarifas",
    DATOSWEBENFERMEDADES:"/Web/DatosWebEnfermedades",
    FINALIZARENFERMEDADES:"/Web/DatosWebFinalizarEnfermedades",
    GETPOBLACIONES: "/Web/ObtenerPoblaciones"
  },
  IH_INICIAL: "H4sIAAAAAAACCqpWykxRsjI0NtdRSi5RssorzcnRUcpJV7JSSi3WdQ1W0lHKTwNytA0MrQwMgLyMYiDP2TXE1cfVN97VzTXI19XF0QWosBYAAAD//w=="
};

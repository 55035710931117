import { ActionReducerMap, ActionReducer, MetaReducer, Action } from "@ngrx/store";
import { localStorageSync, LocalStorageConfig } from "ngrx-store-localstorage";
import { LanguageActionTypes, LanguageActions } from "./app.actions";
import { UNSET_USER } from "./core/auth/store/auth.actions";
import { authReducer, AuthState } from './core/auth/store/auth.reducer';
import { Utils } from './shared/models/classes/utils.class';


export interface LanguageState {
  preferredLanguage: string;
}

const initialState: LanguageState = {
  preferredLanguage: ''
};

export function languageReducer(state = initialState, action: LanguageActions): LanguageState {
  switch (action.type) {
    case LanguageActionTypes.SetPreferredLanguage:
      return {
        ...state,
        preferredLanguage: action.payload
      };
    default:
      return state;
  }
}

export interface AppState {
  lang: LanguageState;
  usuario: AuthState
}

export function persitsData(reducer: ActionReducer<any>): ActionReducer<any> {
  const config: LocalStorageConfig = {
    keys: [
      { 'loading': { encrypt: Utils.encriptar, decrypt: Utils.encriptar } },
      { 'usuario': { encrypt: Utils.encriptar, decrypt: Utils.encriptar } },
      { 'rma': { encrypt: Utils.encriptar, decrypt: Utils.encriptar } },
      { 'track': { encrypt: Utils.encriptar, decrypt: Utils.encriptar } },

    ],
    rehydrate: true,
    removeOnUndefined: true,
    storage: sessionStorage
  };
  return localStorageSync(config)(reducer);
}

export function clearState(reducer) {
  return function (state, action) {
    if (action.type === UNSET_USER) {
      state = undefined;
      sessionStorage.clear();
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any, Action>[] = [persitsData, clearState];

export const appReducers: ActionReducerMap<any> = {
  lang: languageReducer,
  usuario: authReducer
};


export const arrEffects: any[] = [];

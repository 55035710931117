import { Component, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router, Event } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { GoogleTagManagerService } from "src/app/core/services/google-tag-manager.service";
import { InsuranceService } from "src/app/modules/insurance/insurance.service";
import { IAccordionItem } from "src/app/shared/components/accordion/interfaces/accordion-item.interface";

@Component({
  selector: "aig-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
  // VARIABLES READONLY
  products = undefined; //MainVisuals.PRODUCTS;
  pack = undefined; //MainVisuals.PACK;
  info = undefined; //MainVisuals.INFO;
  moreinfo = undefined; //MainVisuals.MOREINFO;
  coverages: IAccordionItem[] = undefined; //ACCORDION[0];
  faqs: IAccordionItem[] = undefined; //ACCORDION[1];

  @ViewChild("header") header;

  constructor(
    private router: Router,
    private translate: TranslateService,
    public insuranceService: InsuranceService,
    private gtmService: GoogleTagManagerService
  ) {
    this.insuranceService.borrarFormularioSesion();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let header = document.getElementById("header");

        header.scrollIntoView();
      }
    });

    this.translate.get("MAINVISUALS").subscribe((t) => {
      this.pack = t.PACK;
      this.products = t.PRODUCTS;
      this.info = t.INFO;

      this.moreinfo = t.MOREINFO;

      this.faqs = t.FAQ;
      this.coverages = t.COVERAGES;
    });
  }

  ngOnInit(): void {}

  // Función para obtener el contenido adicional del objeto PACK
  getAdditionalContent(item): string {
    // Verifica si el elemento tiene la propiedad additionalContent definida
    if (item.additionalContent) {
      return item.additionalContent;
    }
    // Si no hay additionalContent definido, devuelve un valor genérico
    return "Información adicional no disponible";
  }

  onCalculaCuota(event: MouseEvent, path: string) {
    event.preventDefault(); // Prevent the default behavior

    // Emitir el evento al dataLayer de GTM
    this.gtmService.pushEvent({
      event: "cta",
      event_detail: "calculadora_header",
    });

    // Navegar después de un pequeño retraso para asegurar que el evento GA se envíe
    setTimeout(() => {
      this.router.navigate([path]);
    }, 300);
  }

  onCalculaCuotaBody(event: MouseEvent, path: string) {
    event.preventDefault(); // Prevent the default behavior

    // Emitir el evento al dataLayer de GTM
    this.gtmService.pushEvent({
      event: "cta",
      event_detail: "calculadora_body",
    });

    // Navegar después de un pequeño retraso para asegurar que el evento GA se envíe
    setTimeout(() => {
      this.router.navigate([path]);
    }, 300);
  }

  public scrollToElement($element): void {
    $element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}

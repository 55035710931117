<div class="aig-modal c-modal" [ngClass]="[panelPositionClass, size, type]">
  <div class="aig-modal__wrapper" [style.maxHeight]="newInnerHeight" [style.maxWidth]="customMaxWidth">
    <div class="aig-modal__sticker">
      <div class="aig-modal__header">
        <ng-content select="[header]"></ng-content>
      </div>
  
      <div class="aig-modal__body">
        <ng-content select="[body]"></ng-content>
      </div>
  
      <div class="aig-modal__footer">
        <ng-content select="[buttons]"></ng-content>
      </div>
    </div>
  </div>
</div>
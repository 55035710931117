import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IToastMsg } from './interfaces/toast-msg.interface';



@Injectable({
  providedIn: 'root'
})
export class ToastMsgService {


  private _toast: Subject<IToastMsg> = new Subject<IToastMsg>();

  constructor() { }

  public setToast(toast: IToastMsg){
    this._toast.next(toast);
  }

  public onToast(){
    return this._toast.asObservable();
  }

}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'aig-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  newInnerHeight: string;

  @Input() position: string = '';
  @Input() size: string = '';
  @Input() type: string = '';

  @Input() customMaxWidth: string = '';

  public panelPositionClass: string = '';

  constructor() {
    this.newInnerHeight = `${window.innerHeight*.9}px`;
  }

  ngOnInit(): void {
    this.setPanelPositionClass();
  }

  private setPanelPositionClass(): void {
    switch (this.position) {
      case 'right':
        this.panelPositionClass = 'c-panel--right';
        break;
      case 'left':
        this.panelPositionClass = 'c-panel--left';
        break;
      case 'center':
        this.panelPositionClass = 'c-modal--center';
        break;
      default:
        this.panelPositionClass = '';
        break;
    }
  }


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastMsgComponent } from './toast-msg.component';

import { ToastMsgService } from './toast-msg.service';
import {MessageService} from 'primeng/api';



@NgModule({
  declarations: [ToastMsgComponent],
  exports:  [ToastMsgComponent],
  imports: [
    CommonModule
  ],
  providers: [
    ToastMsgService,
    MessageService
  ]
})
export class ToastMsgModule { }

import { trigger, style, animate, transition } from '@angular/animations';

export const HEIGHT_TO_AUTO = trigger('accordion', [
  transition(':enter', [
    style({  height: 0 }),
    animate('400ms ease-in-out', style({  height : '*'}))
  ]),
  transition(':leave', [
    style({  height: '*' }),
    animate('400ms ease-in-out', style({  height : 0}))
  ])
]);

